<template>
    <div v-if="fetchCustomerFailed">
        <div class="flex h-screen w-screen flex-col lg:flex-row items-center justify-center">
            <div class="lg:mb-0 mb-16 lg:mr-12">
                <div class="text-4xl text-center mb-2">
                    404
                </div>
                <div class="w-72 text-center text-2xl" v-text="$t('consent.landing.404')" />
            </div>
            <towing-illustration class="w-sm" />
        </div>
    </div>

    <div class="min-h-screen h-full flex flex-col" v-else-if="!$wait.is('consent.loading')">
        <header class="h-20 shadow-lg text-gray-700 bg-white xl:h-24">
            <div class="container flex justify-between items-center h-full">
                <h2 class="m-0 text-xl text-center font-semibold text-gray-700 | lg:text-left">
                    {{ accountName }}
                </h2>

                <a class="uppercase link-gray" @click.stop="setLocale(localeInverse)">
                    {{ localeInverse }}
                </a>
            </div>
        </header>

        <main class="w-full flex-1 flex flex-col justify-center items-center gap-6">
            <div class="max-w-sm rounded-sm shadow-xl bg-white">
                <div class="relative flex justify-center rounded-t-sm bg-white">
                    <figure class="-mt-12 p-1.5 rounded-r-lg rounded-tl-lg shadow-lg bg-white">
                        <div class="p-6 border border-gray-300 rounded bg-gray-200">
                            <icon class="text-gray-600 text-6xl" name="regular/email-action-check" v-if="isActive" />
                            <icon class="text-gray-600 text-6xl" name="regular/email-action-disable" v-else />
                        </div>
                    </figure>
                </div>

                <section class="flex flex-col justify-center items-center p-6 gap-6 text-center">
                    <h1 class="m-0 text-2xl">
                        {{ title }}
                    </h1>

                    <p class="m-0 text-gray-600">
                        {{ message }}

                        <span class="cursor-pointer underline whitespace-nowrap" @click="onChangeConsent" v-if="isActive">
                            {{ $t('consent.landing.revokeLinkText') }}
                        </span>
                    </p>
                </section>

                <section class="p-6">
                    <activix-button
                        class="w-full"
                        type="default"
                        :loading="$wait.is('consent.updating')"
                        @click="onClose"
                        v-if="isActive"
                    >
                        {{ $t('consent.landing.close') }}
                    </activix-button>
                    <activix-button
                        class="w-full"
                        type="primary"
                        :loading="$wait.is('consent.updating')"
                        @click="onChangeConsent"
                        v-else
                    >
                        {{ $t('consent.landing.subscribe') }}
                    </activix-button>
                </section>
            </div>
        </main>
    </div>
</template>

<script>
    import ActivixDate from '@/value-objects/ActivixDate.js';
    import CaslConsentType from '@/entities/CaslConsentType.js';
    import TowingIllustration from '@/assets/svg/illustrations/towing.svg';

    export default {
        components: {
            TowingIllustration,
        },

        data() {
            return {
                account: null,
                consentLimitDate: null,
                consentType: null,
                fetchCustomerFailed: false,
                initialConsent: null,
            };
        },

        computed: {
            title() {
                if (this.isActive) {
                    return this.$t('consent.landing.confirmedTitle');
                }

                return this.$t('consent.landing.revokedTitle');
            },

            message() {
                if (this.isActive) {
                    return this.$t('consent.landing.confirmedText', { dealer: this.accountName });
                }

                return this.$t('consent.landing.revokedText', { date: this.consentLimitDate, dealer: this.accountName });
            },

            buttonText() {
                if (this.isActive) {
                    return this.$t('consent.landing.close');
                }

                return this.$t('consent.landing.subscribe');
            },

            customerSid() {
                return this.$route.query.customerSid;
            },

            accountName() {
                return this.account ? this.account.name : '';
            },

            isActive() {
                return this.consentType === CaslConsentType.DOUBLE_OPT_IN;
            },

            localeInverse() {
                return this.$i18n.locale === 'fr' ? 'en' : 'fr';
            },
        },

        methods: {
            async getCustomerAndApproveIfNotActive() {
                this.$wait.start('consent.loading');

                try {
                    const response = await this.$axios.get(`consent/${this.customerSid}`);
                    const customerData = response.data.data;

                    this.account = customerData.account;
                    this.consentLimitDate = new ActivixDate(customerData.consent_limit_date).toDateString();
                    this.consentType = customerData.consent_type;
                    this.revoked = customerData.revoked;
                    this.setLocale(customerData.locale);

                    this.fetchCustomerFailed = false;

                    if (!this.isActive && !this.revoked) {
                        await this.onChangeConsent();
                    }
                } catch (error) {
                    this.fetchCustomerFailed = true;
                }

                this.$wait.end('consent.loading');
            },

            onClose() {
                window.close();
            },

            async onChangeConsent() {
                this.$wait.start('consent.updating');

                try {
                    const response = await this.$axios.put(`consent/${this.customerSid}`, { addConsent: !this.isActive });
                    this.consentType = response.data.data.consent_type;
                    this.revoked = response.data.data.revoked;
                } catch (error) {
                    this.$notify.error(this.$t('consent.cantValidateConsent'));
                }

                this.$wait.end('consent.updating');
            },

            setLocale(locale) {
                this.$i18n.locale = locale;
            },
        },

        created() {
            this.getCustomerAndApproveIfNotActive();
        },
    };
</script>

<style>
    .box-inner-shadow {
        -webkit-box-shadow: inset 5px 4px 34px -26px #000000;
        box-shadow: inset 5px 4px 34px -26px #000000;
    }
</style>
